<template>
  <div>
   <!-- <div class="topheader">
      <div class="searchInput">
        我分销的团
      </div>
      <div class="fixedcss"><passWord /></div>
    </div> -->
    <div class="contents">
     <!-- <div class="tableSearch">
          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="70px">
           <el-form-item style="margin-bottom:0px">
            <el-date-picker v-model="searchForm.date" type="daterange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
        </el-form-item>
             <!-- <el-form-item label="状态" style="margin-bottom:0px">
              <el-select v-model="searchForm.saleStatus" clearable  placeholder="全部" style="width:120px">
                <el-option label="正常" value="0"></el-option>
                <el-option label="关闭" value="1"></el-option>
              </el-select>
            </el-form-item> -->
            <!--<el-form-item label="供应商" style="margin-bottom:0px">
               <el-input v-model="searchForm.supplierName" clearable placeholder="供应商名称"></el-input>
            </el-form-item>
            <el-form-item label="" style="margin-bottom:0px">
              <el-input v-model="searchForm.name" clearable placeholder="团标题" style="width:220px"></el-input>
            </el-form-item>
            <el-button @click="getTopSearch" type="primary" icon="el-icon-search">查询</el-button>
            <el-button @click="chongzhi">重置</el-button>
          </el-form>
        </div> -->
    
 <div class="mainbox">
   <div class="biaoticss"><strong>我的分销团长：{{tuanName}}</strong></div>
    <el-table
        :data="tableData"
        border
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">
        <el-table-column
          prop="name"
          label="团购名称"
        >
        </el-table-column>
        <el-table-column
          prop="supplierName"
          label="供应商"
          width="150" 
          >
        </el-table-column>
        <el-table-column
          label="价格(元)"
          width="160">
          <template slot-scope="scope">
            <strong>{{scope.row.minPrice}} ~ {{scope.row.maxPrice}}</strong>
          </template>
        </el-table-column>
          <!-- <el-table-column
          label="团购有效期">
          <template slot-scope="scope">
            {{scope.row.beginTimes}} ~ {{scope.row.endTimes}}
          </template>
        </el-table-column> -->
        <el-table-column
          prop="statusDescription"
          label="状态" width="100">
        </el-table-column>
        <el-table-column
          prop=""
          label="分销数" width="100" header-align="center" align="center">
        </el-table-column>
        <el-table-column
          prop=""
          label="浏览数" width="100" header-align="center" align="center">
        </el-table-column>
        <el-table-column
          prop=""
          label="订单数" width="100" header-align="center" align="center">
        </el-table-column>
        <el-table-column
          prop=""
          label="销售额" width="100" header-align="center" align="center">
        </el-table-column>
        <!-- <el-table-column
      fixed="right"
      label="操作"
      width="120"  header-align="center" align="center">
      <template slot-scope="scope">
        <el-button @click="uploadFun(scope.row)" type="text" size="small">上传订单</el-button>
      </template>
    </el-table-column> -->
      </el-table>
      <div class="paginations">
          <el-pagination
          background
          layout="total, prev, pager, next, sizes"
          @current-change="changePage" :current-page="page" :page-size="pageSize"
          :page-sizes="[20, 40, 50, 100]"
          :total="total">
        </el-pagination>
      </div>
 </div>

     <!-- <div class="cardList">
     <div class="cardBox" v-for="item in tableData" v:key="item.id">
      
        <div class="cardItem">
         <div class="biaoti"><span>{{item.supplierName}}</span>{{item.name}}</div>
        <div class="pricebox">
           <span>{{item.beginTimes}}至{{item.endTimes}}</span><em>￥</em>{{item.minPrice?item.minPrice:0}}
        </div>
        <div class="imglist">
          <template v-for="(it, indexs) in item.images" v:key="indexs">
            <ol><img :src="it" class="imgsize"/></ol>
          </template>
        </div>
        <div class="caozuoBtn">
          <bdo v-if="item.status == 0">{{item.statusDescription}}</bdo>
          <bdo v-else class="huise">{{item.statusDescription}}</bdo>
             <span><el-button v-if="item.status ==1 && item.favorite" @click.native.prevent="choucang(item.id)">分销该团</el-button></span>
            <span><el-button v-if="item.status ==1 && !item.favorite" @click.native.prevent="cancalBtn(item.id)">取消该团</el-button></span>
            <span><el-button @click.native.prevent="uploadFun(item)">上传订单</el-button></span>
        </div>
        </div>
      </div>
     </div> -->

    </div>

     <el-dialog title="上传订单" :visible.sync="uploadflag" :before-close="handleClose" width="600px">
      <el-form ref="formAdd" :model="formAdd" label-position="right">
        <el-upload
          ref="my-upload"
          class="upload-demo"
          style="text-align:center;"
          drag
          action="string"
          :http-request="UploadImage"
          clearFiles="removeFile"
          multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传excel文件，且不超过10M</div>
        </el-upload>
      </el-form>
    </el-dialog>

    <el-dialog title="订单解析结果" :visible.sync="orderflag" :before-close="ooClose" width="1000px" style="margin-top:5%">
      <div class="orderHeight">
      <el-table
        :data="orderData"
        border
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">
        <el-table-column
          prop="onlineOrderId"
          label="订单号">
        </el-table-column>
        <el-table-column
          prop="goodsName"
          label="商品">
        </el-table-column>
        <el-table-column
          prop="specName"
          label="规格">
        </el-table-column>
        <el-table-column
          prop="num"
          label="数量">
        </el-table-column>
        <el-table-column
          prop="consigneeName"
          label="收货人" width="100px"> 
        </el-table-column>
        <el-table-column
          prop="consigneePhone"
          label="电话" width="120px"> 
        </el-table-column>
        <el-table-column
          prop="consigneeAddress"
          label="收货地址">
        </el-table-column>
         <!-- <el-table-column
          label="操作"
          width="100" header-align="center" align="center">
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="modifyFun(scope.row)">修改</el-button>
      </template>
    </el-table-column> -->
      </el-table>
      </div>
        <div class="midCss" style="padding-top:20px">
          <el-button type="primary" @click="handleSubmit">确认保存</el-button>
          <el-button @click="handleClose">取 消</el-button>
        </div>
    </el-dialog>

    <el-dialog title="订单修改" :visible.sync="modyflag" :before-close="modyClose" width="600px">
      <el-form ref="formAdd" :model="formAdd" label-position="right">
       <el-form-item label="商品名称：">
            <el-input v-model="formAdd.goodsName" placeholder="请输入商品名称"></el-input>
          </el-form-item>
          <el-form-item label="收货人：">
            <el-input v-model="formAdd.consigneeName" placeholder="请输入收货人"></el-input>
          </el-form-item>
          <el-form-item label="联系电话：">
            <el-input v-model="formAdd.consigneePhone" placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <el-form-item label="联系地址：">
            <el-input v-model="formAdd.consigneeAddress" placeholder="请输入联系地址"></el-input>
          </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { tuanzhangFavorite } from "@/api/kaituan";
import { importOrder, batchAdd } from "@/api/orders";
import passWord from "@/components/password"
let moment = require('moment');
export default {
  name: 'Main',
  components: { passWord },
  data() {
    return {
      searchForm: {
        retailerOrgId: ''
      },
      page: 1,
      total: 0,
      pageSize: 20,
      passVisible: false,
      uploadflag: false,
      fileList: [],
      tableData: [],
      orderData: [],
      orderflag: false,
      logining: false,
      modyflag: false,
      downflag: false,
      tuanName: '',
      formAdd: {
        goodsName: '',
        consigneeName: '',
        consigneePhone: '',
        consigneeAddress: ''
      }
    }
  },
  methods: {
    choucang(){
      this.downflag = true
    },
    removeFile(){
      this.$refs['my-upload'].clearFiles()
    },
    cancalBtn(ids){
      var datas = {
        id: ids
      }
      this.$confirm('您确定要取消分销该团?', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        cancelFavorite(datas).then(res => {
           that.$message({
            message: '操作成功',
            type: 'success'
          });
        })
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
    getTopSearch(){
      var that = this
      var datas = that.searchForm;
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.orderByCreated = "DESC";
      tuanzhangFavorite(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
           var nodataArr = res.data.rows || []
          nodataArr.map((item)=>{
            item.beginTimes = moment(item.startTime).format('YYYY-MM-DD HH:mm:ss')
            item.endTimes = moment(item.endTime).format('YYYY-MM-DD HH:mm:ss')
          })
          that.tableData = nodataArr
          that.total = res.data.totalRows
        }else{
          this.$message.error(res.message)
        }
      })
    },
    uploadFun(row){
      console.log(row)
      var that = this
      that.uploadflag = true
      that.uploadId = row.id
    },
    ooClose(){
      this.orderflag = false
    },
    modyClose(){
      this.modyflag = false
    },
    UploadImage(param){
      var that = this
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('id', that.uploadId)
      importOrder(formData).then(res => {
      	console.log('上传图片成功')
        if(res.code === 200){
          param.onSuccess() 
          that.orderflag = true
          this.uploadflag = false
          that.orderData = res.data
          that.total = res.data.length
        }else{
          that.removeFile()
          that.$message.error(res.message)
        }

      }).catch(response => {
        console.log('图片上传失败')
        param.onError()
      })
    },
    handleClose(){
      this.uploadflag = false
    },
    handleSubmit(){
      var that = this
      var objs = {}
      objs.orders = that.orderData
      batchAdd(objs).then(res => {
          this.$router.push({
            path: "/dingdan"
          })
      })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${ file.name }？`)
    },
    addfun(){
      this.$router.push({
        path: "/goods/detail"
      })
    }, 
    modifyFun(row){
      this.modyflag = true
      this.formAdd = row
    },
    downClose(){
      this.downflag = false
    },
    chongzhi(){
      this.searchForm = {
        date: '',
        endTime: '', 
        startTime: '', 
        goods: '',
        name: '',
        status: '0',
        supplierOrgId: ''
      }
    }
  },
  mounted() {
    var ids = this.$route.query.id
    if(ids){
      this.searchForm.retailerOrgId = ids
      this.tuanName = this.$route.query.name
    }
    this.getTopSearch()
  }
}
</script>

<style scoped>

.orderHeight{
  height: 500px;
  overflow-x: hidden;
  overflow-y:scroll;
  -webkit-overflow-scrolling: touch;
}
.cardList{
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: block;
  margin: 0 auto;
  overflow: hidden;
}
@media screen and (max-width: 1338px){
  .cardList{
    width: 844px;
  }
}
@media screen and (min-width: 1338px){
  .cardList{
    width: 1266px;
  }
}
@media screen and (min-width: 1920px){
  .cardList{
    width: 1688px;
  }
}
.cardBox{
  float: left;
  width: 420px;
  overflow: hidden;
}
.cardItem{
  margin: 5px;
  display: block;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 0 10px #eee;
  border-radius: 4px;
}
.biaoticss{
  padding-bottom: 15px;
  font-size: 18px;
  line-height:40px;
}
.biaoti{
  font-size: 14px;
  color: #333;
  height: 44px;
  overflow: hidden;
  line-height: 22px;
  display: block;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.biaoti span{
  background-color: #ff6600;
  color: #fff;
  font-size: 12px;
  padding: 2px 5px;
  margin-right: 5px;
}
.pricebox{
  display: block;
  line-height: 30px;
  color: #ff6600;
  font-weight: bold;
  font-size: 18px;
  height: 30px;
  padding: 5px 0;
}
.pricebox em{
  font-style: normal;
  font-size: 12px;
}
.pricebox span{
  float: right;
  font-size: 12px;
  color: #999;
  font-weight: normal;
}
.imglist{
  display: block;
  overflow: hidden;
  padding-bottom: 10px;
  height: 120px;
}
.imglist ol{
  float:left;
  width: 33.33%;
  margin: 0;
  padding: 0;
}
.imgsize{
  width: 90%;
  height: 120px;
  display: block;
  background-color: #fff;
  border-radius: 5px;
  margin: 0 auto;
}
.caozuoBtn{
  padding: 10px 0 0 0;
  overflow: hidden;
  font-size: 12px;
}
.caozuoBtn bdo{
  color:#07c160;
  line-height:30px;
  padding: 4px 12px;
  border-radius: 20px;
  background: rgba(7,193,96,.08);
}
.caozuoBtn bdo.huise{
  background: rgba(21,21,22,.08);
  color: #9c9c9c;
}
.upload-demo{
  display: block;
  margin: 0 auto;
  padding: 10px 0 50px;
  text-align: center;
}
.caozuoBtn span{
  float: right;
  margin-left: 10px;
  /* border: 1px solid #999;
  padding: 2px 10px;
  line-height:22px;
  border-radius: 4px;
  color:#282828;
  margin-left: 10px;
  cursor: pointer; */
}

.fixedcss{
  position:absolute;
  right: 0;
  top: 0;
  width: 40%;
  height: 50px;
  z-index: 9999;
}
</style>

